import type { Coin, CoinWithAmount, SwapType, CryptoNetwork, SwapForm, CurrencyDeep } from '@base/types'
import type { Prisma } from '@develit-io/fubex-exchange-prisma'
import * as yup from 'yup'

const { cryptoAddressValidation } = useValidations()

export const useSwapStore = defineStore('swap', () => {
  const { platform } = useAppConfig()
  const { user, limitRemaining, isLogged, verifiedLevelNum } = storeToRefs(useUser())

  const validationSchema = yup.object({
    swap: yup.object().shape({
      swapFrom: yup.object().shape({
        amount: yup.number().required('Zadejte požadovanou částku.'),
        price: yup.number().required(),
      }).test({
        name: 'verified-used',
        test: () => {
          if (isLogged.value)
            return verifiedLevelNum.value > 0
          return true
        },
        message: 'Pro zahájení směny musíte mít ověřený bankovní účet. Přejděte do sekce “Můj účet” -> “Bankovní účet” a přidejte bankovní účet, jehož jste majitelem.',
      }).test({
        name: 'personal-limit',
        test: val => {
          if (isLogged.value)
            return val.price * val.amount <= limitRemaining.value
          return true
        },
        message: `Překračujete Váš osobní limit (zbývá ${formatPriceInEur(limitRemaining.value)}). Je potřeba zvýšit úroveň verifikace v sekci “Můj účet” -> "Verifikace".`,
      }).test({
        name: 'maximum-eur',
        test: val => val.amount * val.price <= 100000,
        message: 'Maximální hodnata směny je ekvivalent 100 000 EUR',
      }),
      swapTo: yup.object().shape({
        amount: yup.number()
          .required('To - zadejte částku.')
          .moreThan(0, 'To - musí být větší než 0'),
      }),
    }),
    swapType: yup.string().required().oneOf<SwapType>(['ONETIME', 'RECURRING']),
    fromType: yup.string().required().when(
      'swap.swapFrom.type',
      {
        is: 'FIAT',
        then: () => yup.string().oneOf(['bank'], 'Není vybrán způsob platby'),
        otherwise: () => yup.string().oneOf(['wallet'], 'Není vybrán způsob platby'),
      },
    ),
    fromBankAccount: yup.object().when('fromType', {
      is: 'bank',
      then: schema => schema.required('Není vybrán bankovní účet'),
    }),
    fromWalletAddress: yup.string().when('fromType', {
      is: 'wallet',
      then: () => yup.string().required('Zadejte adresu Vaší peněženky').test({
        name: 'from-address-regex',
        test: val => {
          return validateFromWalletAddress(val)
        },
        message: 'Neplatná adresa peněženky pro zvolenou síť',
      }),
    }),
    fromNetwork: yup.object().when('fromType', {
      is: 'wallet',
      then: schema => schema.required(),
    }),
    toType: yup.string().required().when(
      'swap.swapTo.type',
      {
        is: 'FIAT',
        then: () => yup.string().oneOf(['bank'], 'Není vybrán způsob platby'),
        otherwise: () => yup.string().oneOf(['wallet'], 'Není vybrán způsob platby'),
      },
    ),
    toBankAccount: yup.object().when('toType', {
      is: 'bank',
      then: schema => schema.required('Není vybrán bankovní účet'),
    }),
    toWalletAddress: yup.string().when('toType', {
      is: 'wallet',
      then: () => yup.string().required('Zadejte adresu Vaší peněženky').test({
        name: 'to-address-regex',
        test: val => {
          return validateToWalletAddress(val)
        },
        message: 'Neplatná adresa peněženky pro zvolenou síť',
      }),
    }),
    toNetwork: yup.object().when('toType', {
      is: 'wallet',
      then: schema => schema.required('Nebyla vybrána žádná síť.').test({
        name: 'min-amount',
        test: val => {
          return validateMinOrder(val as CryptoNetwork)
        },
        message: 'Směňovaná částka je nižší než minimum pro zvolenou síť.',
      }),
    }),
    consent: yup.boolean().required().oneOf([true], 'Pro pokračování je potřeba odsouhlasit podmínky'),
  })

  const { values: formValues, isSubmitting, errors, defineField, setFieldValue, resetField, resetForm, handleSubmit, meta: formMeta } = useForm<SwapForm>({
    validationSchema,
    initialValues: {
      swap: {
        calculationDirection: 'from',
      },
      swapType: 'ONETIME',
      fromType: 'bank',
      toType: 'wallet',
      consent: false,
    },
  })

  const [swapTypeValue, swapTypeBinds] = defineField('swapType', { props: VProps })
  const [fromTypeValue, fromTypeBinds] = defineField('fromType', { props: VProps })
  const [fromBankAccountValue, fromBankAccountBinds] = defineField('fromBankAccount', { props: VProps })
  const [fromWalletAddressValue, fromWalletAddressBinds] = defineField('fromWalletAddress', { props: VProps })
  const [fromNetworkValue, fromNetworkBinds] = defineField('fromNetwork', { props: VProps })
  const [toTypeValue, toTypeBinds] = defineField('toType', { props: VProps })
  const [toBankAccountValue, toBankAccountBinds] = defineField('toBankAccount', { props: VProps })
  const [toWalletAddressValue, toWalletAddressBinds] = defineField('toWalletAddress', { props: VProps })
  const [toNetworkValue, toNetworkBinds] = defineField('toNetwork', { props: VProps })
  const [consentValue, consentBinds] = defineField('consent', { props: VProps })
  const [widgetSwapValue, widgetSwapBinds] = defineField('swap', { props: VProps })

  const { tokens } = storeToRefs(useCoins())
  const { getReactiveCoinByCode } = useCoins()
  const { supportedDepositChains } = useCodes()

  const init = async () => {
    await until(tokens).toMatch(c => c.length > 0, { timeout: 10000 })

    resetForm({
      values: {
        ...formValues,
        swap: {
          swapFrom: { ...getReactiveCoinByCode('CZK').value! },
          swapTo: { ...getReactiveCoinByCode('BTC').value! },
          calculationDirection: 'from',
        },
      },
    })

    watchEffect(() => {
      if (formValues.swap.swapFrom?.amount)
        setFieldValue('swap.swapFrom', { ...getReactiveCoinByCode(formValues.swap.swapFrom.code).value!, amount: formValues.swap.swapFrom.amount })

      if (formValues.swap.swapTo?.amount)
        setFieldValue('swap.swapTo', { ...getReactiveCoinByCode(formValues.swap.swapTo.code).value!, amount: formValues.swap.swapTo.amount })
    })
  }

  useAsyncData(() => init())

  const cryptoNetworks = computed(() => {
    if (formValues.swap.swapFrom?.type === 'CRYPTO') {
      return formValues.swap.swapFrom.chainCharges
        .filter(c => supportedDepositChains.includes(`${c.currencyCode}-${c.chainId}`))
        .map<CryptoNetwork>(c => ({
          id: c.chainId.toString(),
          label: c.chain.chainId,
          code: c.chain.chainId,
          charge: c.charge,
        })) ?? []
    }

    if (formValues.swap.swapTo?.type === 'CRYPTO') {
      return formValues.swap.swapTo.chainCharges
        .filter(c => supportedDepositChains.includes(`${c.currencyCode}-${c.chainId}`))
        .map<CryptoNetwork>(c => ({
          id: c.chainId.toString(),
          label: c.chain.chainId,
          code: c.chain.chainId,
          charge: c.charge,
        })) ?? []
    }

    return []
  })

  const swapFrom = computed(() => formValues.swap.swapFrom)
  const swapTo = computed(() => formValues.swap.swapTo)
  const fromNetwork = computed(() => formValues.fromNetwork)
  const toNetwork = computed(() => formValues.toNetwork)

  const conversionCharge = computed(() => {
    let matchingCharge = swapFrom.value?.charges.find(charge => Math.floor(swapFrom.value?.amount || 0) >= charge.amountFrom && Math.floor(swapFrom.value?.amount || 0) <= charge.amountTo)
    if (!matchingCharge) { // swapTo has charges
      matchingCharge = swapTo.value?.charges.find(charge => Math.floor(swapTo.value?.amount || 0) >= charge.amountFrom && Math.floor(swapTo.value?.amount || 0) <= charge.amountTo)
    }

    if (matchingCharge) {
      return roundMath((100 - matchingCharge.profitPlatformPercent - matchingCharge.platform.profitBitbeliPercent) / 100, 4)
    }

    return platform.defaultCharge ?? 0
  })

  const conversionRate = computed(() => {
    if (!swapFrom.value || !swapTo.value)
      return

    return swapFrom.value.price * conversionCharge.value / swapTo.value.price
  })

  const conversionRateString = computed(() => {
    if (!swapFrom.value || !swapTo.value || !conversionRate)
      return

    if (swapFrom.value.type === 'FIAT')
      return `1 ${swapTo.value.code} =  ${formatPriceByCurrency(1 / conversionRate.value!, swapFrom.value.code)}`
    else
      return `1 ${swapFrom.value.code} =  ${formatPriceByCurrency(conversionRate.value!, swapTo.value.code)}`
  })

  const calculateSwapAmounts = () => {
    let res = 0
    let referenceAmount = 0

    if (formValues.swap.calculationDirection === 'from' && formValues.swap.swapFrom!.amount === undefined)
      return

    if (formValues.swap.calculationDirection === 'to' && formValues.swap.swapTo!.amount === undefined)
      return

    if (formValues.swap.calculationDirection === 'from') {
      referenceAmount = formValues.swap.swapTo!.amount!
      if (formValues.swap.swapFrom?.type === 'FIAT')
        res = roundDown(formValues.swap.swapFrom!.amount! * conversionRate.value!, 8)

      else
        res = roundDown(formValues.swap.swapFrom!.amount! * conversionRate.value!, 2)

      if (res < 0.000001)// we had problem with displaying very small numbers in the input in scientific notation 6.6e-7
        res = 0

      setFieldValue('swap.swapTo.amount', res)
    }
    else {
      referenceAmount = formValues.swap.swapFrom!.amount!
      if (formValues.swap.swapTo?.type === 'FIAT')
        res = roundUp(formValues.swap.swapTo.amount! / conversionRate.value!, 8)
      else
        res = roundUp(formValues.swap.swapTo!.amount! / conversionRate.value!, 2)

      if (res < 0.000001)// we had problem with displaying very small numbers in the input in scientific notation 6.6e-7
        res = 0

      setFieldValue('swap.swapFrom.amount', res)
    }

    if (referenceAmount !== res)
      calculateSwapAmounts()
  }

  const calculateConversion = (amount: number, from: Coin, to: Coin, conversionCharge: number) => {
    if (from.type === 'FIAT')
      return amount * from.price / conversionCharge / to.price
    else
      return amount * from.price * conversionCharge / to.price
  }

  const chargeInFiat = computed(() => {
    const { amount: _amountFrom, ...from }: CoinWithAmount = swapFrom.value!
    const { amount: _amountTo, ...to }: CoinWithAmount = swapTo.value!
    if (toNetwork.value?.charge !== undefined)
      return calculateConversion(toNetwork.value?.charge, to, from, 1)
  })

  const setCryptoToken = (coin: Coin) => {
    if (swapFrom.value?.type === 'FIAT')
      setFieldValue('swap.swapTo', { ...coin, amount: swapTo.value?.amount })
    else
      setFieldValue('swap.swapFrom', { ...coin, amount: swapFrom.value?.amount })
  }

  // set default values when swap changes from fiat2crypto to crypto2fiat and vice versa
  watch(swapFrom, () => {
    if (swapFrom.value?.type === 'CRYPTO') {
      setFieldValue('fromType', 'wallet')
      setFieldValue('toType', 'bank')
    }
    else {
      setFieldValue('fromType', 'bank')
      setFieldValue('toType', 'wallet')
    }
  })

  const swapFromCurrencyCode = computed(() => swapFrom.value?.code)
  watch(swapFromCurrencyCode, () => {
    resetField('fromNetwork')
    setFieldValue('fromNetwork', cryptoNetworks.value[0])
  }, { deep: false })

  const swapToCurrencyCode = computed(() => swapTo.value?.code)
  watch(swapToCurrencyCode, () => {
    resetField('toNetwork')
    setFieldValue('toNetwork', cryptoNetworks.value[0])
  }, { deep: false })

  const orderInput = computed<Prisma.OrderCreateArgs>(() => ({
    data: {
      orderHash: 'will be generated',
      type: formValues.swapType === 'ONETIME' ? 'ONETIME' : 'RECURRING',
      fromCurrencyCode: swapFrom.value!.code,
      fromAmount: roundMath(swapFrom.value!.amount!, swapFrom.value?.type === 'FIAT' ? 2 : 8),
      fromBankAccountId: formValues.fromBankAccount?.id,
      fromWalletAddress: formValues.fromWalletAddress,
      fromChainId: fromNetwork.value?.id ? formValues.fromNetwork!.id : undefined,
      toCurrencyCode: swapTo.value!.code,
      toAmount: roundMath(swapTo.value!.amount!, swapTo.value?.type === 'FIAT' ? 2 : 8),
      toBankAccountId: formValues.toBankAccount?.id,
      toWalletAddress: formValues.toWalletAddress,
      toChainId: toNetwork.value?.id ? formValues.toNetwork!.id : undefined,
      userId: user.value!.userId,
      email: user.value?.email,
      phoneNumber: user.value?.phoneNumber,
      charge: conversionCharge.value,
      chargeAmount: roundMath(swapFrom.value!.amount! * swapFrom.value!.price * (1 - conversionCharge.value), 2),
      chainCharge: swapTo.value?.type === 'CRYPTO' ? toNetwork.value?.charge : undefined,
      exchangeDirection: swapFrom.value?.type === 'FIAT' ? 'FIAT_TO_CRYPTO' : 'CRYPTO_TO_FIAT',
      toAmountAfterCharge: swapTo.value?.type === 'CRYPTO' ? roundMath(swapTo.value.amount! - toNetwork.value!.charge, 8) : roundMath(swapTo.value!.amount!, 2),
      chargeCurrency: 'EUR',
      conversionRate: swapFrom.value?.type === 'FIAT' ? (1 / conversionRate.value!) : conversionRate.value,
    },
  }))

  function validateMinOrder (val: CryptoNetwork): boolean {
    const minimumOrder = swapTo.value?.chainCharges.find(c => c.chainId === val.id)?.minimumOrder
    if (swapTo.value?.amount && swapTo.value.amount > 0 && minimumOrder)
      return swapTo.value.amount - minimumOrder > 0
    return true
  }

  function validateFromWalletAddress (address: string): boolean {
    if (fromNetwork.value?.code)
      return cryptoAddressValidation(address, fromNetwork.value.code)

    return false
  }

  function validateToWalletAddress (address: string): boolean {
    if (toNetwork.value?.code)
      return cryptoAddressValidation(address, toNetwork.value.code)

    return false
  }

  function initExchange (coin: Coin) {
    setCryptoToken(coin)
    navigateTo('/profile/exchange')
  }

  return {
    formMeta,
    errors,
    handleSubmit,
    isSubmitting,
    cryptoNetworks,
    fromNetwork,
    toNetwork,
    swapFrom,
    swapTo,
    calculateConversion,
    calculateSwapAmounts,
    chargeInFiat,
    conversionRate,
    conversionRateString,
    orderInput,
    conversionCharge,
    setCryptoToken,
    resetForm,
    resetField,
    swapTypeValue,
    fromTypeValue,
    fromBankAccountValue,
    fromWalletAddressValue,
    fromNetworkValue,
    toTypeValue,
    toBankAccountValue,
    toWalletAddressValue,
    toNetworkValue,
    consentValue,
    widgetSwapValue,
    swapTypeBinds,
    fromTypeBinds,
    fromBankAccountBinds,
    fromWalletAddressBinds,
    fromNetworkBinds,
    toTypeBinds,
    toBankAccountBinds,
    toWalletAddressBinds,
    toNetworkBinds,
    consentBinds,
    widgetSwapBinds,
    defineField,
    initExchange,
  }
})
